<template>
  <section
    v-show="user.information"
    class="text-left composeDialog dialogWindow"
    v-bind:class="windowClass"
  >
    <div v-if="!loaExternalEnabled">
      <div class="info-header mt-2">{{ $t("LOA.TITLE") }}</div>

      <p class="mt-2 mb-4">
        {{ $t("LOA.LOA_EXTERNAL_DISABLED") }}{{ loa_level }}
      </p>

      <b-button @click="closeBtn">{{ $t("CANCEL") }}</b-button>
    </div>

    <div v-if="loaExternalEnabled">
      <b-overlay :show="loading" rounded="sm">
        <div v-if="!loading">
          <p>
            {{ $t("COMPOSE.NOT_REGISTERED") }}
          </p>
          <h6>{{ $t("COMPOSE.NOT_REGISTERED_INFORMATION") }}</h6>

          <div class="mt-2 mb-4">
            <NotRegistered
              v-for="(item, index) in external_participants"
              :key="index"
              @setValidated="setValidated"
              :methods="methods"
              :loa_level="loa_level"
              :item="item"
              :index="index"
            ></NotRegistered>
          </div>

          <div v-if="user.email_edit_information_text">
            <h6>{{ $t("COMPOSE.EXTERNAL_TEXT") }}</h6>
            <small>{{ $t("COMPOSE.EXTERNAL_TEXT_INFORMATION") }}</small>
            <div class="mt-2 mb-2">
              <textarea
                :disabled="filteredParticipants.length == 0"
                class="textarea form-control"
                v-model="external_text"
                rows="3"
                :placeholder="external_text_placeholder"
              ></textarea>
            </div>
          </div>

          <div class="mt-2 mb-2">
            <b-button-group>
              <button
                :disabled="disableSend"
                v-on:click="sendBtn"
                class="btn btn-fill btn-primary"
              >
                {{ $t("COMPOSE.BUTTON_DIALOG") }}
              </button>
              <b-button @click="closeBtn">{{ $t("CANCEL") }}</b-button>
            </b-button-group>
          </div>
        </div>
        <div v-if="loading" class="mt-2">
          <InformationMessage type="information">
            <i class="fal fa-info-circle"></i> {{ actionMessage }}
          </InformationMessage>
        </div>
      </b-overlay>
    </div>
  </section>
</template>
<script>
import URLSearchParams from "@ungap/url-search-params";
export default {
  components: {
    NotRegistered: () => import("@/components/Compose/NotRegistered_4_1"),
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
  },
  data() {
    return {
      validated: [],
      methods: {},
      loading: true,
      actionMessage: "Loading",
      userlist: {},
      registered: [],
      loa_level: 1,
      notregistered: [],
      external_text: "",
      external_participants: [],
      locale: this.$i18n.locale,
    };
  },
  methods: {
    async init() {
      await this.getMethods();
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri);
      this.loading = true;
      this.loa_level = params.get("loa_level");
      this.userlist = JSON.parse(params.get("userlist"));
      this.registered = this.userlist.registered;
      this.notregistered = this.userlist.notregistered;
      let default_method = "";
      for (let ax = 0; ax < this.methods.length; ax++) {
        let method = this.methods[ax];
        if (default_method == "") {
          if (this.user.loa_level_enabled.loa3_enabled == 3) {
            if (method.loa_level >= this.loa_level) {
              default_method = method.name;
            }
          }
          if (this.user.loa_level_enabled.loa2_enabled == 2) {
            if (method.loa_level >= this.loa_level) {
              default_method = method.name;
            }
          }
          if (this.user.loa_level_enabled.loa1_enabled == 1) {
            if (method.loa_level >= this.loa_level) {
              default_method = method.name;
            }
          }
        }
      }
      for (let ix = 0; ix < this.notregistered.length; ix++) {
        this.external_participants.push({
          email: this.notregistered[ix],
          authentication_method: default_method,
          authentication_identifier: "",
          language: this.locale,
        });
        if (default_method == "link") {
          this.validated.push({
            email: this.notregistered[ix],
            valid: 1,
          });
        } else {
          this.validated.push({
            email: this.notregistered[ix],
            valid: 0,
          });
        }
      }
      this.loading = false;
    },
    async getMethods() {
      let self = this;
      await this.$http
        .get(this.user.hostname + "/authenticate/external/methods")
        .then(function (result) {
          self.methods = result.data;
        })
        .catch(function (error) {
          self.loading = false;
          if(error.toString() == "Error: fail_4_4") return;
          self.$noty.error(self.$t(error.response));
        });
    },
    setValidated(payload) {
      if (payload.value) {
        this.validated[payload.index].valid = 1;
      } else {
        this.validated[payload.index].valid = 0;
      }
    },
    closeBtn() {
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "CLOSE",
        })
      );
    },
    sendBtn() {
      if (this.external_text == "") {
        this.external_text = this.$t("MESSAGES.EXTERNAL_TEXT_PLACEHOLDER");
      }
      this.$Office.context.ui.messageParent(
        JSON.stringify({
          message: "SEND",
          external_participants: JSON.stringify(this.filteredParticipants),
          removed_participants: JSON.stringify(
            this.filteredRemovedParticipants
          ),
          external_text: this.external_text,
        })
      );
    },
  },
  computed: {
    external_text_placeholder: function () {
      if (this.user.email_information_text != "") {
        return this.user.email_information_text;
      } else {
        return this.$t("MESSAGES.EXTERNAL_TEXT_PLACEHOLDER");
      }
    },
    loaExternalEnabled: function () {
      if (this.loa_level == 3) {
        return this.user.external_loa_levels.loa3;
      }
      if (this.loa_level == 2) {
        return (
          this.user.external_loa_levels.loa3 ||
          this.user.external_loa_levels.loa2
        );
      }
      if (this.loa_level == 1) {
        return (
          this.user.external_loa_levels.loa3 ||
          this.user.external_loa_levels.loa2 ||
          this.user.external_loa_levels.loa1
        );
      }
      return false;
    },
    disableSend() {
      if (this.filteredParticipants == 0 && this.registered.length == 0) {
        return true;
      } else {
        return (
          this.external_participants.length != this.validatedParticipants.length
        );
      }
    },
    filteredParticipants() {
      return this.external_participants.filter(function (item) {
        return item.authentication_method !== "donotsend";
      });
    },
    filteredRemovedParticipants() {
      return this.external_participants.filter(function (item) {
        return item.authentication_method == "donotsend";
      });
    },
    validatedParticipants() {
      return this.validated.filter(function (item) {
        return item.valid == 1;
      });
    },
    windowClass() {
      if (this.$Office.context.diagnostics.platform == undefined) {
        return "";
      } else {
        if (this.$Office.context.diagnostics.platform == "OfficeOnline") {
          return "pr-2";
        } else {
          return "pl-md-4 pr-md-4";
        }
      }
    },
  },
  mounted() {
    console.log("ComposeDialog mounted");
    this.init();
  },
};
</script>
<style></style>
